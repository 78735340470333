import {
    AUTH0_API_CLIENT_CONFIG,
    AUTH0_MANAGE_IDENTITIES_TOKEN_CONFIG,
    LOGIN_RETURN_PATH,
    LOGOUT_REDIRECT_PATH,
    LOGOUT_RETURN_PATH,
} from '~@constants/authentication';
import { EAuth0IdentityMigrationAction, EAuth0Provider, IAuth0IdToken, IAuth0Identity } from '~interfaces/Auth0';
import { LogoutOptions, RedirectLoginOptions, User, useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import React, { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useLocalState from '~hooks/useLocalState';

export type AuthRedirectOptions = {
    backUrl?: string;
    loginHint?: string;
    next?: string;
    redirect?: boolean;
    screenHint?: string;
};

export type AuthContext = {
    auth0Error?: Error;
    auth0IdToken?: IAuth0IdToken;
    auth0Identities?: IAuth0Identity[];
    auth0Login: (options?: AuthRedirectOptions, loginOptions?: RedirectLoginOptions) => void;
    auth0Logout: (options?: AuthRedirectOptions, logoutOptions?: LogoutOptions) => void;
    auth0UpdateClaims: () => Promise<void>;
    auth0User?: User;
    auth0UserId?: string;
    currentLogin?: number;
    identityMigrationAction: EAuth0IdentityMigrationAction;
    identityMigrationUrl?: string;
    isAuthenticated: boolean;
    isLoading: boolean;
    isReady: boolean;
    login: (options?: AuthRedirectOptions) => void;
    loginProvider?: EAuth0Provider;
    logout: (options?: AuthRedirectOptions) => void;
    previousLogin?: number;
    suggestLinkAccounts: boolean;
};

const AuthContext = createContext<AuthContext>({
    isLoading: true,
    isAuthenticated: false,
    isReady: false,
    identityMigrationAction: EAuth0IdentityMigrationAction.NONE,
    suggestLinkAccounts: false,
    auth0UpdateClaims: () => {
        throw new Error('AuthContext has not been implemented');
    },
    login: () => {
        throw new Error('AuthContext has not been implemented');
    },
    logout: () => {
        throw new Error('AuthContext has not been implemented');
    },
    auth0Login: () => {
        throw new Error('AuthContext has not been implemented');
    },
    auth0Logout: () => {
        throw new Error('AuthContext has not been implemented');
    },
});

type AuthProviderProps = {
    children: ReactNode;
};

function AuthProvider(props: AuthProviderProps): JSX.Element {
    const { children } = props;
    const [isReady, setIsReady] = useState(false);
    const [claims, setClaims] = useState<IAuth0IdToken | undefined>(undefined);
    const {
        actions: { setActiveRoleId },
    } = useLocalState();
    const navigate = useNavigate();
    const {
        user: auth0User,
        error: auth0Error,
        isAuthenticated,
        isLoading,
        logout: performAuth0Logout,
        loginWithRedirect,
        getIdTokenClaims,
        getAccessTokenSilently,
        getAccessTokenWithPopup,
    } = useAuth0();

    const auth0Login = useCallback(
        async (options?: AuthRedirectOptions, loginOptions?: RedirectLoginOptions) => {
            setActiveRoleId(undefined);
            console.log('auth0Login', options, loginOptions);
            await loginWithRedirect({
                authorizationParams: {
                    screen_hint: options?.screenHint,
                    login_hint: options?.loginHint ? encodeURIComponent(options?.loginHint ?? '') : undefined,
                    back_url: options?.backUrl ? encodeURIComponent(options?.backUrl ?? '') : undefined,
                },
                appState: {
                    returnTo: encodeURIComponent(
                        `${LOGIN_RETURN_PATH}${options?.next ? `?next=${encodeURIComponent(options.next)}` : ''}`,
                    ),
                },
                ...loginOptions,
            });
        },
        [loginWithRedirect, setActiveRoleId],
    );

    const auth0Logout = useCallback(
        (options?: AuthRedirectOptions, logoutOptions?: LogoutOptions) => {
            const { redirect, ...queryParams } = options || {};

            // Encode loginHint if it exists
            queryParams.loginHint = queryParams.loginHint ? encodeURIComponent(queryParams.loginHint) : undefined;
            queryParams.next = queryParams.next ? encodeURIComponent(queryParams.next) : undefined;

            // Make query params string
            const queryString = Object.entries(queryParams)
                .filter(([, value]) => !!value && !!value.length)
                .map(([key, value]) => `${key}=${value}`)
                .join('&');

            // Assemble returnTo URL allowing query strings to get back to login page to be used in next login redirect
            const returnTo = `${window.location.origin}${redirect ? LOGOUT_REDIRECT_PATH : LOGOUT_RETURN_PATH}${
                queryString.length ? `?${queryString}` : ''
            }`;

            // Clear active role before logging out
            setActiveRoleId(undefined);

            // Logout of auth0
            void performAuth0Logout({
                logoutParams: {
                    returnTo,
                },
                ...logoutOptions,
            });
        },
        [performAuth0Logout, setActiveRoleId],
    );

    const login = useCallback(
        (options?: AuthRedirectOptions) => {
            navigate('/login', { state: options });
        },
        [navigate],
    );

    const logout = useCallback(
        (options?: AuthRedirectOptions) => {
            navigate('/logout', { state: options });
        },
        [navigate],
    );

    const updateClaims = useCallback(async () => {
        try {
            await getAccessTokenSilently({
                cacheMode: 'off',
                authorizationParams: {
                    audience: AUTH0_API_CLIENT_CONFIG.audience,
                },
            });
            // const claims = await getIdTokenClaims(AUTH0_API_CLIENT_CONFIG);
            const claims = await getIdTokenClaims();
            setClaims(claims as IAuth0IdToken);
        } catch (e) {
            console.error('AuthContext:Error fetching claims', e);
            if (e instanceof Error && (e.message === 'Consent required' || e.message === 'Login required'))
                await getAccessTokenWithPopup(AUTH0_MANAGE_IDENTITIES_TOKEN_CONFIG);
        }
    }, [getAccessTokenSilently, getAccessTokenWithPopup, getIdTokenClaims]);

    useEffect(() => {
        if (isAuthenticated) void updateClaims();
    }, [isAuthenticated, updateClaims]);

    // set isReady after the first attempt to load the auth0 user
    useEffect(() => {
        if (!isLoading) setIsReady(true);
    }, [isLoading]);

    const auth0UserId = useMemo(() => auth0User?.sub?.split('|')[1] ?? undefined, [auth0User]);

    const identityMigrationAction = useMemo(() => {
        return (
            (claims?.['https://mer.no/identity_migration_action'] as EAuth0IdentityMigrationAction) ||
            EAuth0IdentityMigrationAction.NONE
        );
    }, [claims]);

    const identityMigrationUrl = useMemo(() => {
        return (claims?.['https://mer.no/identity_migration_url'] as string) || undefined;
    }, [claims]);

    const suggestLinkAccounts = useMemo(() => {
        return !!(claims?.['https://mer.no/suggest_link'] && claims?.['https://mer.no/suggest_link_accounts']?.length);
    }, [claims]);

    const previousLogin = useMemo(() => {
        return (claims?.['https://mer.no/previous_login'] as number) || undefined;
    }, [claims]);

    const currentLogin = useMemo(() => {
        return (claims?.['https://mer.no/current_login'] as number) || undefined;
    }, [claims]);

    const loginProvider = useMemo(() => {
        return (claims?.['https://mer.no/login_provider'] as EAuth0Provider) || undefined;
    }, [claims]);

    const auth0Identities = useMemo(() => {
        return (claims?.['https://mer.no/identities'] as IAuth0Identity[]) || undefined;
    }, [claims]);

    const context = {
        isLoading,
        isAuthenticated,
        isReady,
        auth0User,
        auth0UserId,
        auth0Error,
        auth0Login,
        auth0Logout,
        auth0IdToken: claims,
        auth0UpdateClaims: updateClaims,
        auth0Identities,
        identityMigrationAction,
        identityMigrationUrl,
        suggestLinkAccounts,
        previousLogin,
        currentLogin,
        loginProvider,
        login,
        logout,
    };

    return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
}

const useAuth: () => AuthContext = () => useContext(AuthContext);
export { AuthProvider, useAuth };
