import {
    LayoutBlocked,
    LayoutDefault,
    LayoutInterrupt,
    LayoutIsolated,
    LayoutWithoutNav,
} from '~components/routeControl/RouteLayouts';
import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthRedirectPage from '~pages/auth/AuthRedirectPage';
import AuthReturnPage from '~pages/auth/AuthReturnPage';
import ExternalRedirect from '~components/routeControl/ExternalRedirect';
import Footer from '~components/layout/Footer';
import FullPageWorkingIndicator from '~components/indicators/FullPageWorkingIndicator';
import LoginPage from '~pages/auth/LoginPage';
import LogoutPage from '~pages/auth/LogoutPage';
import ModalWrapper from '~components/layout/ModalWrapper';
import ProtectedRoutes from '~components/routeControl/ProtectedRoutes';
import React, { Suspense, lazy } from 'react';
import WaitPage from '~pages/WaitPage';
import styled from '@emotion/styled';

const AuthRoutes = lazy(() => import('~pages/auth/AuthRoutes'));
const InterruptRoutes = lazy(() => import('~pages/interrupt/InterruptRoutes'));
const InvitationRoutes = lazy(() => import('~pages/invitation/InvitationRoutes'));
const MembershipRoutes = lazy(() => import('~pages/protected/memberships/MembershipRoutes'));
const MissingDetailsRoutes = lazy(() => import('~pages/protected/MissingDetailsRoutes'));
const OrganizationRoutes = lazy(() => import('~pages/protected/organization/OrganizationRoutes'));
const UserRoutes = lazy(() => import('~pages/protected/user/UserRoutes'));
const ChangeEmailRoute = lazy(() => import('~components/account/ChangeEmailRoute'));

const RegisterRedirectPage = lazy(() => import('~pages/register/RegisterRedirectPage'));
const RegisterUser = lazy(() => import('~pages/register/RegisterUser'));
const RegisterOrganizationAdmin = lazy(() => import('~pages/register/RegisterOrganizationAdmin'));
const RegisterOrganization = lazy(() => import('~pages/register/RegisterOrganization'));
const RegisterCooperative = lazy(() => import('~pages/register/RegisterCooperative'));
const RegisterAdmin = lazy(() => import('~pages/register/RegisterAdmin'));
const RegisterSMB = lazy(() => import('~pages/register/RegisterSMB'));

const MigrateOrganizationPage = lazy(() => import('~pages/migrate/MigrateOrganizationPage'));
const FinalizePage = lazy(() => import('~pages/protected/FinalizePage'));
const OrderNoMorePage = lazy(() => import('~pages/order/OrderNoMore'));
const AftermarketOrderingNoMorePage = lazy(() => import('~pages/aftermarketOrdering/AftermarketOrderingNoMore'));
const NoMatchRoute = lazy(() => import('~pages/NoMatch'));

const MER_CONTACT_PAGE_URL = 'https://no.mer.eco/kontakt-oss/';
const MER_FAQ_PAGE_URL = 'https://no.mer.eco/mer-hub-faq/';
const MER_PRIVACY_PAGE_URL = 'http://no.mer.eco/personvernerklaering-for-mer/';
const MER_TERMS_PAGE_URL = 'https://no.mer.eco/vilkar-og-betingelser-hos-mer/';

const App: React.FC = () => {
    const { t } = useTranslation(['common']);

    // TODO : handle the layout change on invitation routes in the inviation route handler
    /*
        const isLoggedIn = isReady && isAuthenticated;
        const { isAuthenticated, isReady } = useUser();
        <EnforcedRoute
        path={'/invitation'}
        layout={isLoggedIn ? LayoutWithoutNav : LayoutIsolated}
        element={<InvitationRoutes />}
        />\
    */

    return (
        <>
            <ModalWrapper />
            <AppContainer>
                <Suspense fallback={<FullPageWorkingIndicator text={t('busy.loading.page')} />}>
                    <Routes>
                        <Route path="/changeEmail" element={<ChangeEmailRoute />} />
                        <Route path={'/contact'} element={<ExternalRedirect to={MER_CONTACT_PAGE_URL} />} />
                        <Route path={'/faq'} element={<ExternalRedirect to={MER_FAQ_PAGE_URL} />} />
                        <Route path={'/support'} element={<ExternalRedirect to={MER_CONTACT_PAGE_URL} />} />
                        <Route path={'/privacy'} element={<ExternalRedirect to={MER_PRIVACY_PAGE_URL} />} />
                        <Route path={'/terms'} element={<ExternalRedirect to={MER_TERMS_PAGE_URL} />} />

                        <Route element={<LayoutInterrupt />}>
                            <Route path={'/interrupt/*'} element={<InterruptRoutes />} />
                        </Route>

                        <Route element={<LayoutBlocked />}>
                            <Route path={'/login'} element={<LoginPage />} />
                            <Route path={'/logout'} element={<LogoutPage />} />
                            <Route path={'/return'} element={<AuthReturnPage />} />
                            <Route path={'/redirect'} element={<AuthRedirectPage />} />
                            <Route path={'/wait'} element={<WaitPage />} />
                        </Route>

                        <Route element={<LayoutIsolated />}>
                            <Route path={'/auth/*'} element={<AuthRoutes />} />
                            <Route path={'/order/*'} element={<OrderNoMorePage />} />
                            <Route path={'/bestill-lader/*'} element={<AftermarketOrderingNoMorePage />} />
                            <Route path={'/register'} element={<RegisterRedirectPage />} />
                            <Route path={'/migrate/organization/*'} element={<MigrateOrganizationPage />} />
                            <Route path={'/membership/*'} element={<MembershipRoutes />} />
                            <Route path={'/missing/*'} element={<MissingDetailsRoutes />} />
                        </Route>

                        <Route element={<ProtectedRoutes requireRegistered={false} requireAuthenticated={false} />}>
                            <Route element={<LayoutWithoutNav />}>
                                <Route path={`/register/cooperative/:step?`} element={<RegisterCooperative />} />
                                <Route
                                    path={`/register/organization/:step?/:action?`}
                                    element={<RegisterOrganization />}
                                />
                            </Route>
                            <Route element={<LayoutIsolated />}>
                                <Route path={'/invitation/*'} element={<InvitationRoutes />} />
                            </Route>
                        </Route>

                        <Route element={<ProtectedRoutes requireRegistered={false} screenHint={'signup'} />}>
                            <Route element={<LayoutIsolated />}>
                                <Route path={'/finalize'} element={<FinalizePage />} />
                                <Route path={`/register/admin/*`} element={<RegisterAdmin />} />
                                <Route path={`/register/user/*`} element={<RegisterUser />} />
                                <Route path={`/register/smb/*`} element={<RegisterSMB />} />
                                <Route path={`/register/organizationadmin/*`} element={<RegisterOrganizationAdmin />} />
                            </Route>
                        </Route>

                        <Route element={<ProtectedRoutes requireAuthenticated={true} requireRegistered={true} />}>
                            <Route element={<LayoutIsolated />}>
                                <Route path={'/finalize'} element={<FinalizePage />} />
                            </Route>
                            <Route element={<LayoutDefault />}>
                                <Route path={'/organization/*'} element={<OrganizationRoutes />} />
                                <Route path={'/*'} element={<UserRoutes />} />
                            </Route>
                        </Route>

                        <Route path={'*'} element={<NoMatchRoute />} />
                    </Routes>
                </Suspense>
                <Footer />
            </AppContainer>
        </>
    );
};

export default App;

const AppContainer = styled.div`
    min-height: 100vh;
    padding-top: 4rem;
`;
